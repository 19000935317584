namespace BlockPuzzle {
    export enum BlockColor {
        RED = 0,
        GREEN = 1,
        YELLOW = 2,
        BLUE = 3,
        ORANGE = 4,
        WHITE = 5,
        PURPLE = 6
    }
}